(function ($) {

  // $(function () {
  //   var pushMenu = new mlPushMenu(
  //     $('.sb-menu')[0],
  //     $('.sb-menu--trigger')[0],
  //     {
  //       wrapper: $('.site-container')[0]
  //     }
  //   );

  //   $('.close-menu').click(function() {
  //     console.log('beforeClick', pushMenu.open);
  //     pushMenu.open = false;
  //     console.log('afterClick', pushMenu.open);
  //   });

  //   console.log(pushMenu);
  // });

})(jQuery);
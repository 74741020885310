jQuery(function($) {  
  // Remove the 'no-js' <body> class
  $("html").removeClass("no-js");

  //Disable pointer events on scroll
  var disablePointerEventsOnScroll = function() {
    var root = document.documentElement;
    var timer;

    window.addEventListener(
      "scroll",
      function() {
        // User scrolling so stop the timeout
        clearTimeout(timer);
        // Pointer events has not already been disabled.
        if (!root.style.pointerEvents) {
          root.style.pointerEvents = "none";
        }

        timer = setTimeout(function() {
          root.style.pointerEvents = "";
        }, 250);
      },
      false
    );
  };
  disablePointerEventsOnScroll();

  // Partners slider, shows up on pages with slider enabled
  $('.partners-slider').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    draggable: true,
    autoplay: true,
    prevArrow: $('.slick-prev'),
    nextArrow: $('.slick-next'),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  });

  $('.explore-container__hamburger-menu').click(function() {
    $('.explore-container__dropdown-menu-container').removeClass("shrink");
    $('.explore-container__dropdown-menu-container').addClass("grow");
  });

  $('.explore-container__dropdown-menu-container__nav-menu-x-container').click(function() {
    $('.explore-container__dropdown-menu-container').removeClass("grow");
    $('.explore-container__dropdown-menu-container').addClass("shrink");
  });

  $('.search-form .search-form-input').keypress(function (e) {
    if (e.which == 13) {
      $('form.search-form').submit();
      return false;    //<---- Add this line
    }
  });

  var polylangLanguage = $('html')[0].lang;

  if (polylangLanguage == "en-US") {
    $(".search-form .search-form-input").attr("placeholder", "What are you looking for?").val("");
  } else if (polylangLanguage == "es-MX") {
    $(".search-form .search-form-input").attr("placeholder", "¿Qué busca?").val("");
  }


  if ($('body').hasClass('page-template-archive')) {
		$('.nav-wrap__date-wrap').slick({
			infinite: false,
			slidesToShow: 5,
			slidesToScroll: 1,
			dots: false,
			draggable: true,
			autoplay: false,
			prevArrow: $('.nav-wrap__year-slick-prev'),
			nextArrow: $('.nav-wrap__year-slick-next'),
		});
	};

	if ($('body').hasClass('page-template-archive')) {
		$(".year-secondary-nav__nav-wrap").removeClass('no-display');
	};

  if ($('body').hasClass('date')) {
		$('.nav-wrap__date-wrap').slick({
			infinite: false,
			slidesToShow: 5,
			slidesToScroll: 1,
			dots: false,
			draggable: true,
			autoplay: false,
			prevArrow: $('.nav-wrap__year-slick-prev'),
			nextArrow: $('.nav-wrap__year-slick-next'),
		});
	};

	if ($('body').hasClass('date')) {
		$(".year-secondary-nav__nav-wrap").removeClass('no-display');
	};

});


//SEARCH BAR
var callback = function() {
  // let headerSearchWidget = document.querySelector(".topbar-widget .widget_search");
  // let headerSearchSubmitButton = document.querySelector(".topbar-widget .widget_search .search-form-submit");
  // let headerSearchInputField = document.querySelector(".topbar-widget .search-form-input");

  // headerSearchSubmitButton.value = "";

  // headerSearchSubmitButton.addEventListener("mouseover", function() {
  //   headerSearchInputField.classList.add('search-active');
  // });

  // headerSearchWidget.addEventListener("mouseleave", function() {
  //   headerSearchInputField.classList.remove('search-active');
  // });

  //ONLY EXECUTE JAVASCRIPT BELOW ON TABLET/MOBILE
  var limitFunc = function(){
    // console.log("Mobile Menu Logic Initialized");

    if (window.innerWidth < 1025){
      //CLOSE MENUS WHEN ANOTHER IS CLICKED
      let megaMenusWithSubmenus = document.querySelectorAll(".mega-menu-item.mega-menu-item-has-children");

      for (let i = 0; i < megaMenusWithSubmenus.length; i++) {
        let clickedElement = megaMenusWithSubmenus[i];

        clickedElement.addEventListener("click", function(event) {
          let targetElement = event.target || event.srcElement;
          let targetElementParent = targetElement.parentElement;

          for (let k = 0; k < megaMenusWithSubmenus.length; k++)  {
            let currentMegaMenu = megaMenusWithSubmenus[k];

            if (currentMegaMenu !== targetElementParent) {
              currentMegaMenu.classList.remove("mega-toggle-on")
            }
          }
        });
      }

      //CLOSE ALL MENUS WHEN TOGGLE IS CLICKED
      let megaMenuToggle = document.querySelector(".mega-toggle-blocks-right");

      megaMenuToggle.addEventListener("click", function() {
        for (let k = 0; k < megaMenusWithSubmenus.length; k++)  {
          let currentMegaMenu = megaMenusWithSubmenus[k];
          currentMegaMenu.classList.remove("mega-toggle-on")
        }
      });
    }
  };

  window.addEventListener("resize", limitFunc);
  window.addEventListener("load", limitFunc);


  //SECONDARY MENU
  const openSubMenu = document.getElementById('secondary-submenu');

  openSubMenu.addEventListener('keydown', (event) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      openSubMenu.click();
    }
  });

  const closeSubMenu = document.getElementById('close-secondary-submenu');

  closeSubMenu.addEventListener('keydown', (event) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      closeSubMenu.click();
    }
  });
};

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  callback();
} else {
  document.addEventListener("DOMContentLoaded", callback);
}
